/* khmer */
@font-face {
  font-family: 'Battambang';
  font-style: normal;
  font-weight: 400;
  src: local('Battambang'), url(../fonts/battambang.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}
/* khmer */
@font-face {
  font-family: 'Koulen';
  font-style: normal;
  font-weight: 400;
  src: local('Koulen'), url(../fonts/koulen.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}